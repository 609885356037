import "./src/styles/bootstrap-grid.min.css"
import "./src/styles/semantic-ui-components.css"
import "./src/styles/main.scss"

export const onInitialClientRender = () => {
  //Remove Gatsby reference
  document.getElementById("___gatsby").removeAttribute("id")
  const ids = document.querySelectorAll('*[id^="gatsby"]')
  ids.forEach(el => el.removeAttribute("id"))
}
